@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@font-face {
 font-family: 'HelveticaNeue';
 src: url('../fonts/HelveticaNeue.eot?') format('eot'), 
      url('../fonts/HelveticaNeue.woff') format('woff'), 
      url('../fonts/HelveticaNeue.ttf')  format('truetype'),
      url('../fonts/HelveticaNeue.svg#HelveticaNeue') format('svg');
}
body{
	padding: 0px;
	margin:0px;
	background:#FFF url("../uploads/images/crossword.png");
	font-family: "HelveticaNeue", Helvetica, Arial, sans-serif;
	font-size: 16px;
	color: #262626;
}
a:hover{text-decoration:none;}
h1,h2,h3,h4,h5,h6{font-family: 'Montserrat', sans-serif;font-weight: 700;}
p{
	font-family: "HelveticaNeue", Helvetica, Arial, sans-serif;
	font-size:16px;
	color: #262626;
	line-height: 22px;
    margin-bottom: 17px;

}
a.readmore{
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  text-decoration:none;
  color:#29a5db;
border: 1px solid #29a5db;
padding: 8px;
background: #f7f7f7;
border-radius: 3px;
}
a.readmore:after {
  content: '»';
  position: absolute;
  opacity: 0;
  top: 8px;
  right: -15px;
  transition: 0.5s;
  font-size: 22px;
  padding-right: 8px;
  line-height: 22px;
}
a.readmore:hover{
  padding-right: 30px;
  color: #F79838;
}
a:hover.readmore:after{
  opacity: 1;
  right: 0;
  color: #F79838;
}

ul{padding:0px;margin:0px;}
h1{
	font-size: 28px;
	text-transform: uppercase;
	color:#444;
	font-weight: 700;
	margin-bottom:30px;
}
h2{
	font-size: 26px;
	text-transform: uppercase;
	color:#444;
	font-weight: 700;
}
/*----- Start Special Popup -----*/
.wrape-sp-pop{
    position: fixed;
    z-index: 999;
    bottom: 0;
    right: 60px;
    width: 340px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
-webkit-box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.6);
-moz-box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.6);
box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.6); 
background-color: #fff;
}
.flip360 {
  overflow: hidden;
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.flip360:hover,button:hover .flip360 {
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg);
  -moz-transform: rotate(0); }
.btn-pop{
    position: relative;
    color: #fff;
    background-color: rgb(224, 118, 40);
    font-size: 22px;
    padding: 7px 15px;
    border: none;
    border-radius: 0;
    display: block;
    width: 100%;
    text-align: center;
-o-transition: all 0.6s;
-ms-transition: all 0.6s;
-moz-transition: all 0.6s;
-webkit-transition: all 0.6s;
}
.btn-pop:hover {
background-color: #c95e10
}
.btn-pop i {
background: #fff;
color: #e07628;
padding: 3px;
width: 27px;
height: 27px;
border-radius: 50%;
margin: 0 3px -6px 0;
display: inline-block;
}
.btn-pop span {
height: 10px;
width: 14px;
overflow: hidden;
display: block;
position: absolute;
right: 10px;
top: calc(50% - 5px);
}
.btn-pop span:before {
display: block;
margin-top: -10px
}
span.btn-o:before {
margin-top: 0px
}
.special-pop-body {
	display: none;
	-o-transition: all 0.6s;
	-ms-transition: all 0.6s;
	-moz-transition: all 0.6s;
	-webkit-transition: all 0.6s;
}
.special-pop-content {
padding: 20px 30px;
background-color: #c95e10;
color: #fff
}
.special-pop-content ul:last-child {
margin-bottom: 0;
padding-left: 25px;
}
.special-pop-content li {
list-style: none;
}
.special-pop-content a {
color: #fff;
text-decoration: underline;
}
.special-pop-content li:before {
font-family: FontAwesome;
content: "\f00c";
margin-left: -26px;
margin-right: 10px;
color: #5cb85c
}
.showpop {
display: block;
}
/*----- End Special Popup -----*/

.clear{clear: both;}
.wrape_header{
	width:100%;
	height: auto;
	height: auto;
	background: #fff;
}
.header{
	position: relative;
	width: 100%;
	min-height: 155px;
	background: url(../uploads/images/header-bg.jpg);

}
.phone a{
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	color: #148dc4;
	font-weight: 700;
	margin-top: 45px;
	width: 160px;
	display: block;
}
.phone a:before{
	width: 26px;
	height: 26px;
	position:relative;
	display: block;
	content: '';
	background: url(../uploads/images/icon.png) -3px -3px;
	margin-left: 65px;
	margin-bottom: 10px;
}
.logo h1{margin:0px;text-align: center;}
.logo img{margin: 15px auto;}
.book a{
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	color: #148dc4;
	font-weight: 700;
	margin-top: 45px;
	text-align: right;
	width: 85px;
    float: right;
}
.book a:before{
	width: 30px;
	height: 30px;
	position:relative;
	display: block;
	content: '';
	background: url(../uploads/images/icon.png) -39px -3px;
	margin-left: 30px;
	margin-bottom: 6px;
}

/*==============Menu================*/
#menu{
	width: 100%;
	background:#F79838;
} 
.navbar-collapse{
	padding:0px;
	border-top: 0px;
	box-shadow: inset 0 0 0 rgba(255, 255, 255, 0.1);
}
.navbar-header{

}
.nav_menu{
	margin: 0 auto;
    width: 910px;
}
.nav_menu ul{margin:0px;padding:0px;}
.nav_menu ul li{
	display: inline-block;
}
.nav_menu ul li:first-child a:before{
	content: "";
    position: relative;
    display:block;
    width: 26px;
    height: 26px;
    top: 0px;
	background:url('../uploads/images/icon.png') -3px -43px no-repeat!important;
	margin: 0 auto;
	margin-bottom: 5px;
}
.nav_menu ul li:nth-child(2) a:before{
	content: "";
    position: relative;
    display:block;
    width: 34px;
    height: 26px;
    top: 0px;
	background:url('../uploads/images/icon.png') -39px -43px no-repeat!important;
	margin: 0 auto;
	margin-bottom: 5px;
}
.nav_menu ul li:nth-child(3) a:before{
	content: "";
    position: relative;
    display:block;
    width: 26px;
    height: 26px;
    top: 0px;
	background:url('../uploads/images/icon.png') -83px -43px no-repeat!important;
	margin: 0 auto;
	margin-bottom: 5px;
}
.nav_menu ul li:nth-child(4) a:before{
	content: "";
    position: relative;
    display:block;
    width: 34px;
    height: 26px;
    top: 0px;
	background:url('../uploads/images/icon.png') -119px -44px no-repeat!important;
	margin: 0 auto;
	margin-bottom: 5px;
}
.nav_menu ul li:nth-child(5) a:before{
	content: "";
    position: relative;
    display:block;
    width: 34px;
    height: 26px;
    top: 0px;
	background:url('../uploads/images/icon.png') -163px -44px no-repeat!important;
	margin: 0 auto;
	margin-bottom: 5px;
}
.nav_menu ul li:nth-child(6) a:before{
	content: "";
    position: relative;
    display:block;
    width: 24px;
    height: 26px;
    top: 0px;
	background:url('../uploads/images/icon.png') -206px -43px no-repeat!important;
	margin: 0 auto;
	margin-bottom: 5px;
}
.nav_menu ul li:nth-child(7) a:before{
	content: "";
    position: relative;
    display:block;
    width: 35px;
    height: 26px;
    top: 0px;
	background:url('../uploads/images/icon.png') -241px -43px no-repeat!important;
	margin: 0 auto;
	margin-bottom: 5px;
}
.nav_menu ul li a{
    font-size:14px;
    text-transform: capitalize;
    color:#fff;
   	display: inline-block;
   	padding: 22px 35px 22px 35px;
   	font-family: 'Montserrat', sans-serif;
   	text-transform: uppercase;
   	font-weight:700;
}


.nav_menu ul li a.menuactive{color:#fff}
.nav_menu ul li a:hover, .nav_menu ul li a:focus{
	color:#fff;
    background: none !important;
}
.nav_menu ul ul{display:none;position: absolute;}
.navbar-header{background:#c95e10;}
.navbar-toggle .icon-bar{
    width: 35px;
    height: 4px;
    background: #fff;
    border-radius:2px;
}

.navbar-toggle .icon-bar + .icon-bar{margin-top:6px;}


/*============Content===========*/
.home_content{padding-right: 90px;padding-top:100px;}
.home_content h1{margin-top:0px;margin-bottom:20px;}
.home_content p{text-align: justify;}
.content_bg{}
.content{
	padding:50px 0px;
}
.home_feature{
	width: 100%;
	height: auto;
	background: #e7e7e7  url(../uploads/images/gallery-bg.jpg) repeat;
}
.feature_desc{
	width: 100%;
	padding: 80px 260px 50px 120px;
}
.feature_desc h1{margin-top:0px;color:#444;margin-bottom: 30px;}
.feature_desc p{color:#444;text-align: justify;}
.home_more{
	font-size:16px;
	color:#29a5db;
	font-weight: 400;
	font-family: 'Montserrat', sans-serif;
	line-height: 62px;
}
.home_more:after{
	width: 8px;
	height:15px;
	display: inline-block;
	position: relative;
	content: '';
	background: url(../uploads/images/icon.png) -78px -3px no-repeat;
	margin-left: 10px;
    top: 2px;
}
.home_more:hover{color:#ff9900;}
.tripadvisor{
	width: 100%;
	height: auto;
	background:#639c45;
}
.tripadvisor img{
	margin: 0 auto;
    padding-top: 130px;
    width: 150px;
}
.home_offer{
	width: 100%;
	height: auto;
	min-height: 300px;
	padding: 50px 0px; 
}
.home_offer h1{text-align: center;margin:0px 0px 20px 0px;}
.home_offer_item h2{
	margin:0px;
	text-align: center;
	background: #fff url(../uploads/images/seigaiha.png);
	background-size: 200px;
}
.home_offer_item h2 a{
	display: block;
	font-size: 24px;
	color: #393939;
	text-transform: uppercase;
	font-weight: 400;
	padding: 35px 0px;
}
.home_offer_more{
	width: 100%;
	background: #fff;
	height: 65px;
	padding: 0px 20px;
	text-align: right;
}
.home_offer_more a{
	font-size:16px;
	color:#29a5db;
	font-weight: 400;
	font-family: 'Montserrat', sans-serif;
	line-height: 62px;
}
.home_offer_more a:after{
	width: 8px;
	height:15px;
	display: inline-block;
	position: relative;
	content: '';
	background: url(../uploads/images/icon.png) -78px -3px no-repeat;
	margin-left: 10px;
    top: 2px;
}
.home_gallery{
	width: 100%;
	height: auto;
	min-height: 300px;
	background: url(../uploads/images/gallery-bg.jpg) repeat;
	padding: 50px 0px;
}
.gallery{position:relative;}
.bg-gallery{
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(41, 165, 219, 0.9);
	opacity: 0;
	-webkit-transition: all 0.2s ease 0.2s;
    -o-transition: all 0.2s ease 0.2s;
    transition: all 0.2s ease 0.2s;
}
.bg-gallery p{
	text-align: center;
    margin-top: 45%;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.2s ease 0.2s;
    -o-transition: all 0.2s ease 0.2s;
    transition: all 0.2s ease 0.2s;
}
.bg-gallery:hover{
	opacity: 1;
}
.bg-gallery:hover p{
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}
.more_gallery{text-align: center;margin-top: 30px;}
.more_gallery a{
	font-size: 16px;
    color: #29a5db;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 12px;
}
.more_gallery a:after{
	width: 50px;
    height: 2px;
    position: relative;
    content: '';
    background: #404040;
    display: block;
    text-align: center;
    margin: 0 auto;
    top: 7px;
}
.footer{
	width:100%;
	height: auto;
	background: url(../uploads/images/footer-bg.jpg) repeat;
}
.footer_top{
	padding:80px 0px;
	border-bottom:1px solid #474545;
	min-height:429px;
}
.footer_top h3{
	margin-top: 0px;
	margin-bottom: 30px;
	font-size: 14px;
	color:#fff;
	text-transform: uppercase;
}
.address p{
	color:#c9c9c9;
}
.social{
	width: 210px;
	margin-top: 40px;
}
.social ul{
	list-style: none;
}
.social ul li{
	float: left;
	margin-left: 11px;
}
.social ul li:first-child{
	margin-left: 0px;
}
.facebook:before, .twitter:before, .google:before, .instagram:before{
	width: 44px;
	height: 44px;
	display: inline-block;
	content: '';
	position: relative;

}
.facebook:before{
	background:url('../uploads/images/icon.png') -3px -80px no-repeat;
}
.twitter:before{
	background:url('../uploads/images/icon.png') -57px -80px no-repeat;
}
.google:before{
	background:url('../uploads/images/icon.png') -111px -80px no-repeat;
}
.instagram:before{
	background:url('../uploads/images/icon.png') -165px -80px no-repeat;
}
.f_menu ul{list-style: none;}
.f_menu ul li a{
	color: #c9c9c9;
	text-transform: uppercase;
	font-size:12px;
	letter-spacing: 0.5px;
}
.footer_bottom{
	min-height: 85px;
}
.footer_bottom p{
	margin:0px;
	text-align: center;
	margin-top:30px;
	color:#c9c9c9;
}

/*===========Room Feature===========*/

.room_item{
	background: #f7f7f7;
	margin-bottom: 30px;
}
.room_img{
	padding:20px 0px 20px 20px;
}
.room_desc{
	padding: 70px 60px 30px 60px;
}
.room_desc h2{
	margin-top:0px;
	margin-bottom: 30px;
}
.room_desc h2 a{
	text-transform: uppercase;
	color: #29a5db;
}
.room_desc p{margin-bottom: 30px;color:#737373;}
.room_more{width:130px}


/*===========Service Feature===========*/

.service_item{

}
.service_img{overflow: hidden;position: relative;}
.service_box{
	position: absolute;
	width:100%;
	height: 100%;
	background: #fff;
	opacity: 0;
	top: 0px;
	    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}
.service_item:hover .service_box{
	opacity: 0.9;
}
.service_item h3{
	text-align: center;
    font-size: 14px;
    font-family: 'Arial';
    text-transform: uppercase;
    margin-top: 30%;
}
.service_box p{
	text-align: center;
    font-size: 14px;
    font-family: 'Arial';
    text-transform: uppercase;
    margin-top: 30%;
    font-weight: 700;
}
.service_img img{
	-webkit-transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
}
.service_item:hover .service_img img{
	-webkit-transform: scale(1.1) rotate(0.01deg);
        transform: scale(1.1) rotate(0.01deg);
}
.service_desc{
	padding: 20px 0px 10px;
}
.service_desc h2{
	margin-top:0px;
	margin-bottom: 20px;
	color: #000;
	text-transform: uppercase;
	font-size:18px;
}
.service_desc h2 a{
	color: #000;
	text-transform: uppercase;
	font-size:18px;
}
.service_desc p{margin-bottom: 30px;color:#727272}

/*===========Promotion Feature===========*/

.promo_item{
	margin-bottom: 30px;
}
.promo_box{
	position: absolute;
	width:100%;
	height: 100%;
	background: #fff;
	opacity: 0;
	top: 0px;
	-webkit-transition: all .4s;
    -moz-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
    z-index: 9999;
}
.promo_item:hover .promo_box{
	opacity: 0.9;
}
.promo_img{
	padding:10px 10px 10px 10px;
	border: 1px solid #f7f7f7;
	position: relative;
}
.promo_img_inner{
	overflow: hidden;
}
.promo_img_inner img{
	overflow: hidden;
	-webkit-transition: all .4s;
    -moz-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}
.promo_item:hover .promo_img img{
	-webkit-transform: scale(1.1) rotate(0.01deg);
    transform: scale(1.1) rotate(0.01deg);
}
.promo_desc{
	padding: 0px 0px 0px 30px;
}
.promo_desc h2{
	font-size: 22px;
	margin-top:0px;
	margin-bottom: 30px;
}
.promo_desc h2 a{
	text-transform: uppercase;
	color: #000;
}
.promo_desc p{margin-bottom: 30px;color:#737373;}
.promo_more{
	width: 130px;
	display: block;
	margin: 0 auto;
	margin-top:15%;
	-webkit-transition: all .4s;
    -moz-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}
.promo_item:hover .promo_more{
	margin-top: 22%;
}
.promo_book{width:130px}

/*===========Contact Form===========*/

.wr_info{margin-top: 80px;margin-bottom: 50px;}
/* Flip Card */
.flip-container {
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
    transform-style: preserve-3d;
    -ms-transform: perspective(1000px);
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d; 
    -ms-transform-style: preserve-3d; 
}
.flip-container p{text-align:center}
.flip-container:hover .back {
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
}
.flip-container:hover .front {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
}

/* END: Accommodating for IE */

.flip-container, .front, .back {
    width: 100%;
    height: auto;
    min-height: 200px;
    margin-bottom:60px;
}

/* flip speed goes here */
.flipper {
    transition: 0.8s;
    transform-style: preserve-3d;
    position: relative;
}

/* hide back of pane during swap */
.front, .back {
    backface-visibility: hidden;
    transition: 0.8s;
    transform-style: preserve-3d;
    position: absolute;
    top: 0;
    left: 0;
    background: #f7f7f7 !important;
}

/*  UPDATED! front pane, placed above back */
.front {
    z-index: 2;
    transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
    transform: rotateY(-180deg);
}
.support-box{
    padding: 60px 50px 10px 50px;
    text-align: center;
}
.support-box-title {
    position: absolute;
    background: rgba(41, 165, 219,0.9);
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 50%;
    top: -30px;
    left: 50%;
    margin-left: -30px;
}
.support-box h4{text-transform: uppercase;}
.fa-phone:before{
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  content: '';
  background: url(../uploads/images/icon.png)no-repeat -97px -3px;
  top:20px;
}
.fa-mail:before{
  width: 19px;
  height: 16px;
  display: inline-block;
  position: relative;
  content: '';
  background: url(../uploads/images/icon.png)no-repeat -123px -3px;
  top:20px;
}
.fa-address:before{
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  content: '';
  background: url(../uploads/images/icon.png)no-repeat -152px -3px;
  top:20px;
}
.label{
    color: #000;
    font-size: 13px;
    text-transform: capitalize;
    padding: 0px;
    letter-spacing: 0.3px;
    display: block;
    text-align: left;
}
.txt, .txtarea, .code {
    width:100%;
    padding: 15px 10px;
    margin-top: 5px;
    border: 0px;
    border-bottom: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    background: #f7f7f7;
    color: #000;
    letter-spacing: 0.3px;
}
.txtarea{
    height: 150px;
}
.chapcha{margin-top:15px;width:180px;}
.btnsubmit {
    background: #29a5db;
    border: 0px;
    padding: 20px 20px;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;font-weight: 700;
    color: #fff;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

/*===========Room Detail==========*/

.wr_detail{
	margin-top: 50px;
	padding-bottom: 25px;
    border-bottom: 1px solid #cccccc;
}
.detail_feature h2{
	margin-top:0px;
	font-size: 16px;
}
.detail_feature p{text-align: justify;}
.detail_feature ul{list-style: none;}
.detail_feature ul li{line-height: 22px;}
.detail_feature ul li:before{
	width: 6px;
	height: 6px;
	display:inline-block;
	content: '';
	background: url(../uploads/images/icon.png)no-repeat -174px -3px;
	position: relative;
	top:-1px;
	margin-right: 15px;
}
.room-more{
	width:150px;
	margin: 0 auto;
}
.room-more a{
	text-align: center;
	display: block;
	background: #29a5db;
    color: #fff;
    padding: 15px 0px;
    margin-top: 40px;
    font-family: 'Montserrat', sans-serif;font-weight: 700;
    text-transform: uppercase;
}

/*===========Photo Gallery===========*/
.g-in-offset{
	margin:0px -13px;
}
.g-out-offset{
	margin:0px -2px;
}
.photo-gallery{position:relative;margin-bottom:4px;}
.photo-gallery img{
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}
.bg-photo-gallery{
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(41, 165, 219, 0.9);
	opacity: 0;
	-webkit-transition: all 0.2s ease 0.2s;
    -o-transition: all 0.2s ease 0.2s;
    transition: all 0.2s ease 0.2s;
    -webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}
.bg-photo-gallery p{
	text-align: center;
    margin-top: 25%;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.2s ease 0.2s;
    -o-transition: all 0.2s ease 0.2s;
    transition: all 0.2s ease 0.2s;
}
.link-icon{
	margin-left:46%;
	bottom:35%;
	position: absolute;
	-webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease 0.3s;
    -o-transition: all 0.3s ease 0.3s;
    transition: all 0.3s ease 0.3s;
}
.bg-photo-gallery:hover .link-icon{
	bottom:40%;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}
.link-icon:before{
	width:26px;
	height: 26px;
	display: inline-block;
	content: '';
	background: url(../uploads/images/icon.png)no-repeat -192px -3px;

}
.bg-photo-gallery:hover{
	opacity: 1;
}
.bg-photo-gallery:hover p{
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}
div.wpro-u{
	margin-bottom: 58px;
	border-bottom: 1px solid #cccccc;
}
div.wpro-u .readmore{
	margin-top: 25px;
}
div.wpro-u, div.wpro-up p{
	text-align: center!important;
}
div.wpro-u ul{
	list-style: none;
}
div.wpro-u .img-responsive{
	margin: 0 auto;
}

/*===========Breaking Point==========*/
@media (max-width: @screen-xs-max) {
/* -- Start Popup ---*/
.wrape-sp-pop{
    position: relative;
    bottom: inherit;
    right: inherit;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
}
/* -- End Popup ---*/


	.xspddTop{
		padding-top: 20px;
	}
	.wr_book{background: #393939;padding: 0px 15px;}
	.wr_book a{
		color: #fff;
		text-transform: uppercase;
    	font-weight: 700;
    	font-family: 'Montserrat', sans-serif;
	}
	.phone_tel{padding: 20px 0px;position:relative;}
	.phone_tel:after{
		    width: 42px;
	    height: 60px;
	    position: absolute;
	    display: inline-block;
	    content: '';
	    background: url(../uploads/images/icon.png) -3px -140px;
	    right: 0px;
	    top: 0px;
	}

	.phone-book{
		float: right;
		padding: 20px 0px;
	}
	.nav_menu{width:100%;background: #393939;}
	.nav_menu ul li{display: block;border-bottom: 1px solid #333;}
	.nav_menu ul li a{display: block;text-align: center;}
	.nav_menu ul li:first-child a:before,
	.nav_menu ul li:nth-child(2) a:before,
	.nav_menu ul li:nth-child(3) a:before,
	.nav_menu ul li:nth-child(4) a:before,
	.nav_menu ul li:nth-child(5) a:before,
	.nav_menu ul li:nth-child(6) a:before,
	.nav_menu ul li:nth-child(7) a:before{
		display: none;
	}
	.home_content{padding-right:0px;}
	.home_feature{margin-top:30px;}
	.feature_desc{padding:50px 15px 50px 15px;}
	.home_offer_item{margin-bottom:30px;}
	.footer_top h3{text-align: center;}
	.address{padding-bottom: 30px;border-bottom: 1px solid #2b2b2b;}
	.address p{text-align:center;}
	.social{margin: 0 auto;}
	.wrape_f_menu{padding: 30px 0px}
	.f_menu ul li{text-align: center;}
	.wrape_fb_page{padding-top: 30px;border-top: 1px solid #2b2b2b;border-bottom: 1px solid #2b2b2b;}
	.wrape_fb_page img{margin: 0 auto;}
	.tripadvisor{
		min-height: 360px;
	}
	.room_img{
		padding:20px;
	}
	.room_desc{padding: 20px;}
	.promo_desc h2{text-align: center;}
	.promo_desc{
		padding: 30px 0px 20px 0px;
	}
	.promo_book{margin: 0 auto}
	.detail_gallery{margin-top:40px;}
	.home_content{
		padding-top: 35px;
	}
	.img-home{
		margin-top: 35px;
	}
}
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	.home_content{
		padding-top: 35px;
	}
	.img-home{
		margin-top: 35px;
	}
	#menu{min-height:auto;}
	.nav_menu{width:720px;}
	.nav_menu ul li a{padding: 20px 23px 20px 23px;font-size:13px;}
	.nav_menu ul li:first-child a:before,
	.nav_menu ul li:nth-child(2) a:before,
	.nav_menu ul li:nth-child(3) a:before,
	.nav_menu ul li:nth-child(4) a:before,
	.nav_menu ul li:nth-child(5) a:before,
	.nav_menu ul li:nth-child(6) a:before,
	.nav_menu ul li:nth-child(7) a:before{
		display: none;
	}
	.feature_desc{padding: 50px 50px 0px 50px;}
	.home_more{line-height: 50px;}
	.home_offer_item h2 a{
		padding: 20px 0px;
	}
	.home_offer_more{
		height: 50px;
	}
	.home_offer_more a{
		line-height: 50px;
	}
	.tripadvisor{
		min-height: 360px;
	}
	.room_desc h2{text-align: center;margin-bottom:50px;}
	.room_desc p{
		overflow: hidden;
		text-overflow: ellipsis;
    	white-space: nowrap;
    	display: none;
	}
	.room_more{margin: 0 auto;}
	.promo_desc{
		padding: 30px 0px 20px 0px;
	}
	.promo_more a{padding:7px;font-size:12px;}
	.detail_gallery{margin-top:40px;}
}
@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
	.container{width:100%;padding:0px -15px}
	#menu{min-height:auto;}
	.nav_menu{width:840px;}
	.nav_menu ul li a{padding: 20px 30px 20px 30px;}
	.nav_menu ul li:first-child a:before,
	.nav_menu ul li:nth-child(2) a:before,
	.nav_menu ul li:nth-child(3) a:before,
	.nav_menu ul li:nth-child(4) a:before,
	.nav_menu ul li:nth-child(5) a:before,
	.nav_menu ul li:nth-child(6) a:before,
	.nav_menu ul li:nth-child(7) a:before{
		display: none;
	}
	.feature_desc{
		padding: 50px 50px 0px 50px;
	}
	.home_more{line-height:30px;}
	.tripadvisor{min-height: 278px;}
	.tripadvisor img{width:120px;}
}
@media (min-width: @screen-lg-min) {
    
}